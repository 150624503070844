.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 7rem;
    gap: 1rem;
    color: white;
}

.f-icons{
    display: flex;
    gap: 2rem;
}

@media screen and (max-width: 480px){
    .f-content{
      transform: scale(.7);
      gap: 0.5rem;
      margin-top: 3rem;
    }
  }