.services {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 50vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 13rem;
}

.awesome {
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome> :nth-child(1) {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(3) {
    color: var(--gray);
    font-size: 14px;
    font-weight: bold;
}

.s-button{
    width: 8rem;
    font-weight: 900;
    height: 2rem;
    line-height: 1rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}

.cards>*{
    position: absolute;
}

.s-blur2{
    left: 14rem;
    top:8rem;
}

.s-blurl{
    top: 13rem;
    left:-18rem;
}

@media screen and (max-width:480px) {
    .services{
        margin-top: 0;
        flex-direction: column;
        gap:5rem;
        height: 66rem;
        padding: 0;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }
    .cards>*{
        position: static;
    }
}